import ClassNames from 'classnames'
import * as React from 'react'
import styled from 'styled-components'
import { getInquiryLink } from 'utils/locale'
import I18n from '../../core/i18n'
import * as constants from '../../static/constants'
import SVG from 'react-inlinesvg'

import { LocaleSelect } from 'components/atoms'

import Burger from './Burger'
import Avatar from './HeaderAvatar'

interface IWindow {
  localePath: { [key: string]: string }
  location: any
}

declare var window: IWindow

interface IProps {
  user: any
  isSignedIn: boolean
  desktop?: boolean
  mobile?: boolean
  lang?: { [key: string]: string }
  top?: boolean
  fromDashboardHeader?: boolean
}

interface IMenuListProps {
  user: any
  inquiryLink: string
  isSignedIn?: boolean
  desktop?: boolean
  mobile?: boolean
}

const MenuList: React.VFC<IMenuListProps> = props => (
  <ul
    className={ClassNames('Profile_Menu', {
      desktop: props.desktop,
      mobile: props.mobile,
    })}
  >
    {props.isSignedIn ? (
      <>
        {(props.user.agent || props.user.guide) && (
          <>
            <li>
              <a href="/dashboard">{I18n.t('top_nav.dashboard')}</a>
            </li>
          </>
        )}
        {props.user.agent && (
          <li>
            <a href="/agent/agent_skills">{I18n.t('top_nav.agent')}</a>
          </li>
        )}
        {props.user.admin && (
          <>
            <li>
              <a href="/admin/posts">{I18n.t('top_nav.admin')}</a>
            </li>
            <li>
              <a href="/admin/guides">{I18n.t('generic.search_guide')}</a>
            </li>
          </>
        )}
        {props.user.guest && (
          <>
            <li>
              <a href="/favorites">{I18n.t('top_nav.favorites')}</a>
            </li>
            <li>
              <a href="/reservations">{I18n.t('top_nav.reservations')}</a>
            </li>
            <li>
              <a href="/conversations">{I18n.t('top_nav.conversations')}</a>
            </li>
          </>
        )}
        <li className="separate">
          <a href="/settings">{I18n.t('top_nav.settings')}</a>
        </li>
        {(props.user.guide || props.user.agent) && (
          <li>
            <a href={`/user/${props.user.username}-${props.user.id}`}>
              {I18n.t('generic.show_profile')}
            </a>
          </li>
        )}
        <li>
          <a href="/contacts/new">{I18n.t('top_nav.contacts')}</a>
        </li>
        <li className="separate">
          <a rel="nofollow" data-method="delete" href="/users/sign_out">
            {I18n.t('top_nav.logout')}
          </a>
        </li>
      </>
    ) : (
      <>
        <li>
          <a href="/users/sign_in">{I18n.t('top_nav.login')}</a>
        </li>
        <li>
          <a href="/users/sign_up">{I18n.t('top_nav.signup_guest')}</a>
        </li>
        <li>
          <a href="https://blog.japanwondertravel.com/" target="blank">
            {I18n.t('top_nav.blog')}
          </a>
        </li>
        <li>
          <a href={props.inquiryLink} target="blank">
            {I18n.t('top_nav.inquiry')}
          </a>
        </li>
        <li className="Header_LocaleSelect">
          <LocaleSelect />
        </li>
        {/* {props.lang && (
          <li className="Header_LocaleSelect">
            <LocaleSelect />
          </li>
        )} */}
      </>
    )}
  </ul>
)

const HeaderMenu: React.FC<IProps> = props => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { isSignedIn, user } = props

  const onAvatarClick = () => setIsOpen(!isOpen)

  const inquiryLink = React.useMemo(() => {
    return getInquiryLink(I18n.locale)
  }, [])

  if (props.mobile) {
    return (
      <>
        {props.fromDashboardHeader ? (
          <a href="#" onClick={onAvatarClick}>
            <Avatar_Icon>
              <img src={user.avatar_url} />
            </Avatar_Icon>
          </a>
        ) : (
          <Burger setIsOpen={setIsOpen} />
        )}
        {isOpen && (
          <MobileMenu className="MobileMenu" top={props.top}>
            {props.isSignedIn && <Avatar user={user} />}
            <MenuList user={user} mobile={true} isSignedIn={isSignedIn} inquiryLink={inquiryLink} />
          </MobileMenu>
        )}
      </>
    )
  }
  if (props.desktop) {
    return (
      <Profile dashboard={props.fromDashboardHeader}>
        <Avatar user={user} />
        <MenuList user={user} desktop={true} isSignedIn={isSignedIn} inquiryLink={inquiryLink} />
        <SVG
          src="/images/icons/keyboard_arrow_down.svg"
          className="icon expand"
          aria-hidden="true"
          role="presentation"
        />
      </Profile>
    )
  }

  return null
}

const Avatar_Icon = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${constants.COLORS.white};
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
  margin: 0 10px;

  > img {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }
`

const MobileMenu = styled.div`
  position: absolute;
  top: ${constants.MOBILE_HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  background-color: ${constants.COLORS.white};
  box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
  color: ${constants.COLORS.black};
  padding: 16px 0;
  @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
    position: fixed;
    z-index: 8000;
  }
  .Avatar_Icon {
    margin-left: 80px;
    @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
      margin-left: 50px;
    }
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL - 1}px) {
      margin-left: 16px;
    }
  }
  > ul {
    padding: 16px 0;

    li {
      padding: 8px 80px;
      font-size: 15px;
      @media (max-width: ${constants.BREAKPOINT_DESKTOP}px) {
        padding-left: 50px;
        padding-right: 50px;
      }
      @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL - 1}px) {
        padding-left: 16px;
        padding-right: 16px;
      }

      &:hover {
        background-color: ${constants.COLORS.white};
      }

      a {
        color: ${constants.COLORS.black};
        display: block;
      }
    }
  }

  > ul.Profile_Menu.mobile {
    top: ${constants.MOBILE_HEADER_HEIGHT}px;
    > li.separate {
      border-top: solid 1px ${constants.COLORS.divider};
      margin-top: 8px;
      padding-top: 16px;
    }

    > li.disabled a {
      color: ${constants.COLORS.disabled};
      cursor: default;
    }

    .LocaleSelect {
      width: fit-content;
      color: ${constants.COLORS.black};
      > select {
        padding: 3px 24px 3px 0;
        font-size: 15px;
        > option {
          color: ${constants.COLORS.black};
        }
      }
    }
  }
`

const Profile = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ dashboard }) =>
    dashboard ? constants.MOBILE_HEADER_HEIGHT : constants.HEADER_HEIGHT}px;
  margin-left: 24px;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    .Profile_Menu.desktop {
      display: block;
      z-index: 8000;
    }
  }

  .Profile_Menu.desktop {
    display: none;
    position: absolute;
    top: ${({ dashboard }) =>
      dashboard ? constants.MOBILE_HEADER_HEIGHT : constants.HEADER_HEIGHT}px;
    right: 0;
    width: 180px;
    padding: 12px 0;
    border-radius: 4px;
    background-color: ${constants.COLORS.white};
    box-shadow: 0 5px 20px 0 rgba(21, 27, 38, 0.08);
    font-size: 14px;

    .LocaleSelect {
      padding: 4px 12px;

      > select {
        font-size: 15px;
      }
    }

    > li {
      display: block;
      padding: 4px 12px;
    }

    > li a {
      cursor: pointer;
      color: ${constants.COLORS.black};
      text-decoration: none;
      font-weight: 700;
      &:hover {
        color: ${constants.COLORS.primary};
      }
    }

    > li.disabled a {
      color: ${constants.COLORS.disabled};
      cursor: default;
    }

    > li.separate {
      border-top: solid 1px ${constants.COLORS.divider};
      margin-top: 4px;
      padding-top: 6px;
    }
  }
`

export default HeaderMenu
