export const APP_NAME = 'Japan Wonder Travel'

export const HEADER_HEIGHT = 100
export const MOBILE_HEADER_HEIGHT = 55
export const FOOTER_HEIGHT = 74
export const TOPPAGE_HEADER_HEIGHT = 100
export const TOPPAGE_FOOTER_HEIGHT = 120
export const BREAKPOINT_DESKTOP_XXL = 1439
export const BREAKPOINT_DESKTOP_XL = 1279
export const BREAKPOINT_DESKTOP_LARGE = 1199
export const BREAKPOINT_DESKTOP = 1088
export const BREAKPOINT_TABLET_LARGE = 960
export const BREAKPOINT_TABLET = 767
export const BREAKPOINT_TABLET_SMALL = 720
export const BREAKPOINT_TABLET_MOBILE = 600
export const MAX_TEXT_COUNT = 100
export const MAX_TEXTAREA_COUNT = 5000
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT = 'YYYY/M/D'
export const DAYS_OF_THE_WEEK = ['日', '月', '火', '水', '木', '金', '土']
export const CONFIRMATION_DUE_DATE = 10

const paymentRequiredEl = document.getElementById('payment-required')
export const PAYMENT_REQUIRED = paymentRequiredEl
  ? paymentRequiredEl.getAttribute('data').toLowerCase() === 'true'
  : false

const cloudsignPreviewHostEl = document.getElementById('cloudsign-preview-host')
export const CLOUDSIGN_PREVIEW_HOST = cloudsignPreviewHostEl
  ? cloudsignPreviewHostEl.getAttribute('data')
  : ''

const rewardfuEl = document.getElementById('rewardful')
export const REWARDFUL_ID = rewardfuEl ? rewardfuEl.getAttribute('data') : ''

export const TIME_OPTIONS = [
  { id: 0, name: 'AM:-' },
  { id: 1, name: 'AM:○' },
  { id: 2, name: 'AM:△' },
  { id: 3, name: 'AM:×' },
  { id: 10, name: 'PM:-' },
  { id: 4, name: 'PM:○' },
  { id: 5, name: 'PM:△' },
  { id: 6, name: 'PM:×' },
]

export const START_TIMES = [
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '24:00',
]

export const DAYS = [
  {
    value: 1,
    name: 'Sun',
  },
  {
    value: 2,
    name: 'Mon',
  },
  {
    value: 3,
    name: 'Tue',
  },
  {
    value: 4,
    name: 'Wed',
  },
  {
    value: 5,
    name: 'Thu',
  },
  {
    value: 6,
    name: 'Fri',
  },
  {
    value: 7,
    name: 'Sat',
  },
]

export const HOURS = [
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
]

export const USER_TYPES = [
  { label: 'Admin', key: 'admin' },
  { label: 'Guest', key: 'guest' },
  { label: 'Guide', key: 'guide' },
  { label: 'Agent', key: 'agent' },
]
export const GUIDE_CALENDAR_MARK = {
  available: {
    label: '○',
  },
  maybe: {
    label: '△',
  },
  blocked: {
    label: 'x',
  },
  none: {
    label: '-',
  },
}

export const COLORS = {
  primary: '#00896C',
  secondary: '#4DAC98',
  tertiary: '#9DBFAF',
  success: '#49AE4C',
  successText: '#308532',
  successBG: '#DBEFDB',
  useally: '#F9C43B',
  useallyText: '#CA991C',
  useallyBG: '#FEF3D8',
  caution: '#E23B4F',
  cautionText: '#BA3B4A',
  cautionBG: '#F9D8DC',
  info: '#3DAABB',
  infoText: '#33808C',
  infoBG: '#D8EEF1',
  subText: '#707C97',
  textLink: '#3273DC',
  black: '#333333',
  white: '#FFFFFF',
  background: '#F8F8F8',
  cardBG: '#F3F6F6',
  divider: '#BFC1C7',
  lightGray: '#F1F1F1',
  disabled: '#CBCDD3',
  cardShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)',
  floatShadow: '0px 0px 20px rgba(0, 0, 0, 0.15)',
  modalBackground: 'rgba(191, 193, 199, 0.8)',
  blackOverray: 'rgba(0, 0, 0, 0.3)',
  tooltipBG: 'rgba(0, 0, 0, 0.75)',
  alert: '#6B39F9',
  alertBG: '#E5E7F3',
  info2: '#3B53C7',
  infoBG2: '#EBEDF9',
  info3: '#F08A0D',
  infoBG3: '#FFF2C6',
  danger: '#D85B5B',
  dangerBG: '#F3E5E5',
  background2: '#F5F5F5',
  successBG2: '#E5F3F0',
}
export const SOUNDOUTED_STATUSES = ['soundouted', 'approved', 'declined']
export const REQUESTED_STATUSES = ['requested', 'cancled']
